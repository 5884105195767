export const emailFromAuthor = author => {
  if (!author) return null
  const { email, name } = author
  if (!name) return email
  if (name === email) return email

  // Remove double quotes and backslashes from name
  const sanitizedName = name.replace(/"|\\/g, '')

  return `"${sanitizedName}" <${email}>`
}
